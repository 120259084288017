import React, { useEffect, useState } from "react";
import BreadCrumbs from "../../components/breadcrumbs";
import Banner from "../../components/img_bannerblogs";
import RCMcy from "../../img/Blogbanners1/test/Maximizerevenueandminimizehassleswithacerhealthexperts1.jpg";
import BlogImg10 from "../../img/Blogbanners1/what does billing mean to patients1.jpg";
import BlogImg11 from "../../img/Blogbanners1/challenges in managing healthcare rev cycle1.jpg";
import BlogImg8 from "../../img/Blogbanners1/techniques to increase patient engagement1.jpg";
import BlogImg7 from "../../img/Blogbanners1/obtaining medical billing services from the best medical1.jpg";
import ContactModal from '../../modals/modal_contact';
import Blogcontent from "../../img/Blogcontent1/blog content images 4.jpg";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardImage,
  MDBCardTitle,
  MDBCardText,
  MDBCardBody,
  MDBBtn,
  MDBNavbarLink,
} from "mdb-react-ui-kit";
import { Helmet } from 'react-helmet';
import DownContact from "../../components/downcontact";

const About = () => {
  const [basicModal, setBasicModal] = useState(false);
  const toggleShow = () => setBasicModal(!basicModal);
    const handleCloseModal = () => {
    setBasicModal(false);
  };
  useEffect(() => {
    // change title of page
    document.title = "Healthcare Billing | Maximize revenue & Minimize Hassles";
  }, []);


  // Set Banner Title
  const [BannerTitle, setBannerTitle] = useState({title: "Maximize Revenue and Minimize Hassles with AcerHealth Experts Medical Billing Services", subtitle: "", img:`url(${RCMcy})`});

  // Set DownContact Heading
  const [DownContactTitle, setDownContactTitle] = useState({
    title: "WE HELP DOCTORS COLLECT MORE MONEY FASTER AND EASIER",
    subtitle:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  });

  return (
    <>
     <Helmet>
        <meta name="description" content="Discover streamlined healthcare medical billing with AcerHealth's US-based medical bill services. Simplify your practice and maximize revenue potential today" />
      </Helmet>
      <Banner BannerTitle={BannerTitle} />
      <MDBContainer fluid className="p-0 bg-light">
        <div className="container py-5">
          <div className="row">
            <div className="col-lg-12 mb-5">
              <p className="" style={{fontSize:'18px',fontFamily: "Alegreya Sans",fontWeight:'500' }}>
              Medical professionals struggle to effectively manage their practices in the continuously changing healthcare Practice. Navigating the complicated world of medical billing and revenue cycle management is one of the biggest obstacles. Ineffective billing procedures can cause payments to be delayed, the number of denials to rise, and income to decrease. However, AcerHealth offers workable ways to get beyond these difficulties.
              </p>
              <p className="" style={{fontSize:'18px',fontFamily: "Alegreya Sans",fontWeight:'500' }}>
                Medical billing is a tedious process that necessitates great
                attention to detail. When you contract this important task,
                AcerHealth internal team concentrate on what matters most
                providing first-rate patient care. Making your practice more
                effective and patient-focused by assigning billing duties to
                Acerhealth can increase patient loyalty and happiness.
              </p>
              <p className="" style={{fontSize:'18px',fontFamily: "Alegreya Sans",fontWeight:'500' }}>
              Medical billing firms that specialize in keeping up with rule changes can help guarantee that AcerHealth medical facility follows the most recent rules. This reduces the chances of compliance-related issues and safeguards practice's reputation.
              </p>
              <div className="mb-3">
                <img
                  src={Blogcontent}
                  alt="medical billing experts"
                  className="img-fluid"
                  width={500}
                  height={500}
                />
              </div>
              <p className="" style={{fontSize:'18px',fontFamily: "Alegreya Sans",fontWeight:'500' }}>
              Any healthcare practice must have an efficient revenue cycle. The revenue cycle management process is streamlined when medical billing services are delegated, which shortens the time it takes to process claims and collect money. Since it means quicker payments and faster cash flow this is case on the financial stability of your practice.
              </p>
              <p className="" style={{fontSize:'18px',fontFamily: "Alegreya Sans",fontWeight:'500' }}>
              AcerHealth reliable medical billing service offers thorough reporting that is open and transparent, offering a useful information about the financial health of your clinic. AcerHealth make wise decisions to optimize revenue generation and spot areas for improvement with real-time data and analytics access.
              </p>
              <p style={{fontSize:'18px',fontFamily: "Alegreya Sans",fontWeight:'500' }}>
  If you are looking to elevate your practice's financial performance and improve overall efficiency, consider partnering with AcerHealth expert medical billing services.
  Experience the peace of mind that comes with streamlined billing processes and optimized revenue generation.
  <span style={{ display: 'inline-block', marginLeft: '5px' }}>
    <MDBNavbarLink outline onClick={toggleShow} style={{ color: 'blue',paddingRight:'5px' }}>
      Get in touch
    </MDBNavbarLink>
    <ContactModal show={basicModal} onClose={handleCloseModal} />
  </span>
  with AcerHealth today to explore how we can help your practice thrive in the dynamic healthcare landscape.
</p>
<h3 style={{fontFamily: "Alegreya Sans",fontWeight:'500',fontWeight:'bold' }}>Conclusion </h3>
              <p style={{fontSize:'18px',fontFamily: "Alegreya Sans",fontWeight:'500' }}>Outsourcing medical billing to experienced agency like AcerHealth can help healthcare 
              practices maximize their revenue and minimize administrative hassles. AcerHealth specializes in medical billing for various healthcare providers, 
              including medical specialists, ambulatory surgery centers, multi-specialty groups, hospitals, labs, and health IT. 
              They handle all aspects of the billing process, from coding and claims submission to follow-up and appeals. By outsourcing medical billing, healthcare practices can benefit from improved billing accuracy, increased productivity, and revenue optimization. Additionally, outsourcing helps reduce costs, alleviate administrative burdens, and minimize errors in medical billing. 
              With AcerHealth's expertise and dedicated customer service teams, healthcare practices can effectively manage their revenue cycle and achieve strategic growth. </p>
              <br></br>
            <a href="/" className="text-reset" style={{color:'red'}}>
                 <button style={{background:'#3B0075',color:'white'}}>Learn more</button>
                </a>
            </div>
          </div>
          <MDBRow className="row-cols-1 row-cols-md-4 g-4">
            <MDBCol>
              <MDBCard className="cardtest1">
                <MDBCardImage
                  src={BlogImg10}
                  alt="What Does Billing Mean to Patients"
                  position="top"
                />
                <MDBCardBody>
                  <MDBCardTitle className="cardtitletest" style={{fontFamily:'Alegreya Sans',fontWeight:'bold'}}>
                    <a href="what_does_billing_mean_to_patients">
                      What Does Billing Mean to Patients
                    </a>
                  </MDBCardTitle>
                  <MDBCardText className="pt-4">
                    <small className="text-muted">
                    AcerHealth | Aug - 2024
                    </small>
                  </MDBCardText>
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
            <MDBCol>
              <MDBCard className="cardtest1">
                <MDBCardImage
                  src={BlogImg11}
                  alt="Challenges in Managing the Healthcare Revenue Cycle"
                  position="top"
                />
                <MDBCardBody>
                  <MDBCardTitle className="cardtitletest" style={{fontFamily:'Alegreya Sans',fontWeight:'bold'}}>
                    <a href="Challenges_in_managing_the_healthcare_revenue_cycle">
                      Challenges in Managing the Healthcare Revenue Cycle
                    </a>
                  </MDBCardTitle>
                  <MDBCardText className="pb-4">
                    <small className="text-muted">
                    AcerHealth | Aug - 2024
                    </small>
                  </MDBCardText>
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
            <MDBCol>
              <MDBCard className="cardtest1">
                <MDBCardImage
                  src={BlogImg8}
                  alt="Techniques to Increase Patient Engagement"
                  position="top"
                />
                <MDBCardBody>
                  <MDBCardTitle className="cardtitletest" style={{fontFamily:'Alegreya Sans',fontWeight:'bold'}}>
                    <a href="Techniques_to_Increase_Patient_Engagement">
                      Techniques to Increase Patient Engagement
                    </a>
                  </MDBCardTitle>
                  <MDBCardText className="pt-4">
                    <small className="text-muted">
                    AcerHealth | Aug - 2024
                    </small>
                  </MDBCardText>
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
            <MDBCol>
              <MDBCard className="cardtest1">
                <MDBCardImage
                  src={BlogImg7}
                  alt="Obtain Billing Services from the Best Medical Billing
                  Company"
                  position="top"
                />
                <MDBCardBody>
                  <MDBCardTitle className="cardtitletest" style={{fontFamily:'Alegreya Sans',fontWeight:'bold'}}>
                    <a href="obtain_billing_services_from_the_best_medical_billing_company">
                      Obtain Billing Services from the Best Medical Billing
                      Company
                    </a>
                  </MDBCardTitle>
                  <MDBCardText>
                    <small className="text-muted">
                    AcerHealth | Aug - 2024
                    </small>
                  </MDBCardText>
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
          </MDBRow>
        </div>
      </MDBContainer>
      {/* <DownContact DownContactTitle={DownContactTitle} /> */}
    </>
  );
};

export default About;
